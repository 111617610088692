/*
SPDX-FileCopyrightText: 2023 Genome Research Ltd.

SPDX-License-Identifier: MIT
*/

import { Container, Row, Col, Card } from "react-bootstrap";
import { CentreContents } from '@tol/tol-ui'
import { ReactComponent as TreeOfSexLogo } from '../assets/tos-logo.svg';
import { Timeline } from 'rsuite';
import Sònia from '../assets/headshots/headshot-2.png';
import Paul from '../assets/headshots/headshot-3.png';
import Lukas from '../assets/headshots/headshot-4.png';
import Kamil from '../assets/headshots/headshot-5.png';
import Dan from '../assets/headshots/headshot-6.png';
import Cibele from '../assets/headshots/headshot-7.png';
import Aurora from '../assets/headshots/headshot-8.png';
import Christelle from '../assets/headshots/headshot-9.png';
import Melissa from '../assets/headshots/headshot-10.png';
import Catlin from '../assets/headshots/headshot-11.png';


function Home() {
  const nameArray = [
    'Cibele Sotero-Caio', 'Dan Jeffries', 'Kamil Jaron', 'Paul Jay',
    'Sònia Garcia', 'Lukas Kratochvil', "Aurora Ruiz-Herrera", "Christelle Fraïsse",
    "Melissa A. Wilson", "Caitlin E. McDonough-Goldstein"
  ]

  const photoArray = [Cibele, Dan, Kamil, Paul, Sònia, Lukas, Aurora, Christelle, Melissa, Catlin]

  const committeeInfoArray = [
    "The Tree of Life Programme, Sanger Institute, UK", "Institute for Ecology and Evolution, University of Bern, Switzerland",
    "The Tree of Life Programme, Sanger Institute, UK", "Center for GeoGenetics, University of Copenhagen, Copenhagen, Denmark",
    "Botanical Institute of Barcelona, CSIC, Spain", "Faculty of Science, Charles University, Czech Republic",
    "Faculty of Bioscience, Universitat Autonoma de Barcelona, Spain", "Evolution Ecologie Paléontologie, CNRS, Université de Lille, France",
    "Arizona State University, USA", "University of Wisconsin-Madison, USA; University of Vienna, Austria"
  ]

  const emailArray = [
    "cs43@sanger.ac.uk", "daniel.jeffries@unibe.ch", "kj11@sanger.ac.uk", "paul.yann.jay@gmail.com", "soniagarcia@ibb.csic.es",
    "kratoch1@natur.cuni.cz", "aurora.ruizherrera@uab.cat", "christelle.fraisse@univ-lille.fr", "mwilsons@asu.edu", "mcdonoughgoldstein@gmail.com"
  ]

  const profileLinkArray = ["https://www.sanger.ac.uk/person/sotero-caio-cibele/",
    "https://www.ee.iee.unibe.ch/about_us/team/persons/dr_jeffries_daniel_lee/index_eng.html",
    "https://www.sanger.ac.uk/group/jaron-group/",
    "",
    "https://www.ibb.csic.es/en/research/evolutionary-biology-genome-organisation-plant-uses/staff/sonia-garcia/",
    "", "", "", ""]

  return (
    <div className="tos">
      <CentreContents>
        <Container>
          <Row>
            <div className="col-lg-4" style={{ display: 'flex', justifyContent: 'center' }}>
              <TreeOfSexLogo className="tos-logo" />
            </div>
            <div className="col-lg-8">
              <h2>Our Goal</h2>
              <p className="me-4">The Tree of Sex project will compile all information known for
                reproductive systems across eukaryotic life - from sexual vs asexual reproduction,
                to the evolution of separate sexes, and the degeneration of sex/mating-type chromosomes.
                This information will be centralised in a publicly accessible and curated database that will
                ultimately allow researchers to test wide-reaching questions relating to the evolution of
                reproductive strategies, and how they influence the life history, genomes, adaptation and
                diversification of organisms throughout nature. The Tree of Sex v2.0 builds off the success
                of the first iteration (<a href="http://treeofsex.org/">see here</a>).
              </p>
            </div>
          </Row>
          <Row className="mt-5">
            <Col>
              <h2>Project Roadmap</h2>
              <Timeline className="mb-4">
                <Timeline.Item>
                  June 2023
                  <ul>
                    <li> Prototype of the database</li>
                  </ul>
                </Timeline.Item>
                <Timeline.Item>
                  19th - 23rd June 2023
                  <ul>
                    <li> First Tree of Sex consortium workshop</li>
                    <li> Summary of the workshop can be found <a href="https://sangerinstitute.blog/2023/08/03/new-tree-of-sex-project-collects-all-the-wild-ways-nature-reproduces/">here</a></li>
                  </ul>
                </Timeline.Item>
                <Timeline.Item>
                  <div>
                    June 2023
                    <ul>
                      <li> Registration for the consortium is open!</li>
                      <li> Recruitment of Members</li>
                    </ul>
                  </div>
                </Timeline.Item>
                <Timeline.Item>
                  <div>
                    October 2023
                    <ul>
                      <li> Submit Tree Of Sex COST action proposal</li>
                    </ul>
                  </div>
                </Timeline.Item>
                <Timeline.Item>
                  <div>
                    November 2023
                    <ul>
                      <li> Initiation of Operations Teams</li>
                    </ul>
                  </div>
                </Timeline.Item>
                <Timeline.Item>
                  <div>
                    Jul 2024
                    <ul>
                      <li> Tree of Sex v2.0 Annual workshop (Prague). Planned dates: 2nd - 5th July</li>
                    </ul>
                  </div>
                </Timeline.Item>
                <Timeline.Item>
                  <div>
                    2024
                    <ul>
                      <li> 1st draft of reproductive ontology (ontology team)</li>
                      <li> Initiate Taxon and Topic Teams</li>
                      <li> Perform literature search (literature search team)</li>
                      <li> Establishing workflow and interface of the database (database team)</li>
                      <li> Distribute & process literature (whole consortium)</li>
                    </ul>
                  </div>
                </Timeline.Item>
                <Timeline.Item>
                  <div>
                    2025
                    <ul>
                      <li> Public release of database</li>
                      <li> Opening database for public contributors</li>
                      <li> Analyses of the database data (whole consortium)</li>
                    </ul>
                  </div>
                </Timeline.Item>
              </Timeline>
            </Col>
            <div className="col-lg-8">
              <h2>Join Us!</h2>
              <p>We are an open and highly-collaborative initiative, currently with over 150 members.
                But our task is a big one, so we are always open to new members. Whether you want to
                join/lead a collaborative team to research a particular lineage or question, or are
                just interested in reproductive systems biology and not sure yet how you will contribute,
                all are welcome to join.<br /><br />
                To register, email Kamil Jaron (kj11@sanger.ac.uk),
                Daniel Jeffries (daniel.jeffries@unibe.ch) or any other member of the organisation
                committee and we&apos;ll send you a registration form!
              </p>
            </div>
          </Row>
        </Container>
        <Container className="mt-5">
          <Row>
            <h2>The Tree of Sex Organisation Committee</h2>
          </Row>
          <Row>
            {nameArray.map((person, index) => {
              return (
                <Col key={index}>
                  <Card
                    className="m-lg-5 "
                    style={{ width: '8rem' }}
                  >
                    <Card.Img
                      alt="Headshot of person in committee"
                      src={photoArray[index]}
                      variant="top"
                    />
                    <Card.Body>
                      <Card.Text>
                        {profileLinkArray[index] !== "" ?
                          <a href={profileLinkArray[index]}>{person}</a>
                          :
                          person
                        }
                      </Card.Text>
                      <Card.Text>
                        {committeeInfoArray[index]}
                        <br />
                        <a href={"mailto:" + emailArray[index]}>{emailArray[index]}</a>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </Container>
      </CentreContents>
    </div>
  );
};

export default Home;
